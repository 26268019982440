import React, { ReactElement } from 'react';

import Socials from '../Socials';
import styles from './Introduction.module.scss';

const Introduction = (): ReactElement => {
  return (
    <section className={styles.introductionWrapper}>
      <div className={styles.introductionInfo}>
        <h2>Michał Hachuła</h2>
        <h1>
          Frontend developer with ML passion. <br /> Proficient in <b>React</b>, <b>Vue</b> <br />
          {'&'} avid lover of <b>Typescript</b>.
        </h1>
        <Socials />
      </div>
    </section>
  );
};

export default Introduction;
