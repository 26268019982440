import React, { ReactElement } from 'react';

import gitLogo from '../../assets/github-logo.png';
import gitlabLogo from '../../assets/gitlab-logo.png';
import linkedinLogo from '../../assets/linkedin-logo.png';
import styles from './Socials.module.scss';

function Socials(): ReactElement {
  return (
    <div className="">
      <div className={styles.socials}>
        <a
          href="https://github.com/preacherxp"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLinkGithub}>
          <img src={gitLogo} alt="git" />
        </a>
        <a
          href="https://gitlab.com/michh"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLinkGitlab}>
          <img src={gitlabLogo} alt="gitlab" />
        </a>
        <a
          href="https://www.linkedin.com/in/micha%C5%82-hachu%C5%82a-5389ab1a3/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLinkLinkedIn}>
          <img src={linkedinLogo} alt="linkedin" />
        </a>
      </div>
    </div>
  );
}

export default Socials;
