import './App.scss';

import React, { ReactElement } from 'react';

import Landing from './views/Landing';

function App(): ReactElement {
  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
