import React, { ReactElement } from 'react';

import Introduction from '../Introduction';
import styles from './Landing.module.scss';

function Landing(): ReactElement {
  return (
    <div className={styles.landing}>
      <div className={styles.landingInfo}>
        <Introduction />
      </div>
    </div>
  );
}

export default Landing;
